import * as echarts from 'echarts/lib/echarts';

// 引入需要使用的图表类型，标题，提示信息等
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/pictorialBar';
import 'echarts/lib/chart/radar';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/polar';

// const setEcharts = function(el, options) {
//   let chart = echarts.init(el);
//   chart.setOption(options);
//   window.addEventListener('resize', () => chart.resize());
// };

export default echarts;
