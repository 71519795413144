<template>
  <div class="card">
    <div class="title">
      <div class="left">
        <PieChartTwoTone />
        <span class="title-text">{{ title }}</span>
      </div>
      
      <a-range-picker size="small" v-model:value="rangeDate" />
      <a-button type="primary" size="small" @click="search">查询</a-button>
      <a-button type="primary" size="small" @click="export_click">导出</a-button>
      <a-button size="small" @click="reset">重置</a-button>
    </div>
    <div class="chart-panel">
      <div class="btn-group" :class="cardType == 5 ? 'cardType_5': ''">
        <a-radio-group v-model:value="dateType" @change="handleRadioChange" button-style="solid" size="small">
          <a-radio-button :value="1">今日</a-radio-button>
          <a-radio-button :value="2">本周</a-radio-button>
          <a-radio-button :value="3">本月</a-radio-button>
        </a-radio-group>
        <a-select
          v-model:value="platform"
          allowClear
          @change="handleChange"
          class="itemRight"
          placeholder="请选择违法类型"
          v-if="cardType == 4"
        >
          <a-select-option :value="item.id" v-for="(item, i) in TypesViolationList" :key="i">{{
            item.name
          }}</a-select-option>
        </a-select>
        <span v-if="cardType == 5">总计时长 {{ sum }} 天</span>
      </div>
      
      <div class="chart" :id="`chart-${cardType}`"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue';
import { PieChartTwoTone } from '@ant-design/icons-vue';
import constant from '@/utils/common';
import moment from 'moment';
import echarts from '@/plugins/echarts';
import api from '@/services';
import { message } from 'ant-design-vue';
import { xlsl } from '@/config';

export default defineComponent({
  props: {
    title: String,
    cardType: {
      type: Number,
      default: 1
    }
  },
  components: {
    PieChartTwoTone
  },
  setup(props, context) {
    onMounted(() => {
      initChart();
      getTypesViolation()
    });

    let dateType = ref(3);
    let platform = ref(null)
    let rangeDate = ref();
    let xData = reactive([]);
    let appY = reactive([]);
    let pcY = reactive([]);
    let sum = ref();

    // 初始化 chart
    const initChart = async () => {
      await getHomeChartData();
      let option = (option = {
        legend: {
          data: [props.cardType != 5 ? 'APP端' : '', props.cardType != 5 ? 'PC端' : '']
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData.value
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'APP端',
            data: appY.value,
            type: 'line',
            areaStyle: {},
            smooth: true,
            itemStyle: {
              color: constant.color[Math.floor(Math.random() * 12)]
            }
          },
          {
            name: 'PC端',
            data: pcY.value,
            type: 'line',
            areaStyle: {},
            smooth: true,
            itemStyle: {
              color: constant.color[Math.floor(Math.random() * 12)]
            }
          }
        ]
      });
      let myChart = echarts.init(document.getElementById(`chart-${props.cardType}`));
      myChart.setOption(option);
    };

    // 获取 chart data
    const getHomeChartData = async () => {
      let params = {
        dateType: dateType.value,
        dataType: props.cardType,
        type: platform.value,
        startTime: '',
        endTime: ''
      };
      if(rangeDate.value?.length) {
        params.startTime = moment(rangeDate.value[0]).format('YYYY-MM-DD') + ' 00:00:00'
        params.endTime = moment(rangeDate.value[1]).format('YYYY-MM-DD') + ' 23:59:59'
      } else {
        params.startTime = ''
        params.endTime = ''
      }
      
      let { success, data, msg } = await api.getHomeChartData(params);
      if (success) {
        appY.value = data.app_y;
        pcY.value = data.pc_y;
        xData.value = data.x_data;
        sum.value = data?.sum;
        console.log(sum.value)
      } else {
        message.error(msg);
      }
    };

    const handleRadioChange = e => {
      dateType.value = e.target.value;
      initChart();
    };

    const search = () => {
      // dateType.value = 3;
      initChart();
    };

    const reset = () => {
      if(props.cardType == 4) {
        platform.value = null
      }
      rangeDate.value = [];
      dateType.value = 1;
      initChart();
    };

    const export_click = async () => {
      if(props.cardType == 4 && platform.value != null) {
        location.href = `${xlsl}/api/home-chart-data/export?dateType=${dateType.value}&type=${platform.value}&dataType=${props.cardType}&startTime=${rangeDate.value ? moment(rangeDate.value[0]).format('YYYY-MM-DD') + ' 00:00:00' : ''}&endTime=${rangeDate.value ? moment(rangeDate.value[1]).format('YYYY-MM-DD') + ' 23:59:59' : ''}`
      } else {
        location.href = `${xlsl}/api/home-chart-data/export?dateType=${dateType.value}&dataType=${props.cardType}&startTime=${rangeDate.value ? moment(rangeDate.value[0]).format('YYYY-MM-DD') + ' 00:00:00' : ''}&endTime=${rangeDate.value ? moment(rangeDate.value[1]).format('YYYY-MM-DD') + ' 23:59:59' : ''}`
      }
      console.log(props.cardType)
    }

    const TypesViolationList = ref([]);
    const getTypesViolation = async () => {
      const { data } = await api.getTypesViolation();
      TypesViolationList.value = data;
    };

    const handleChange = () => {
      initChart();
    }
    return {
      dateType,
      xData,
      sum,
      appY,
      pcY,
      rangeDate,
      initChart,
      getHomeChartData,
      handleRadioChange,
      search,
      reset,
      export_click,
      platform,
      TypesViolationList,
      handleChange
    };
  }
});
</script>

<style lang="less" scoped>
.card {
  width: 544px;
  height: 366px;
  padding: 10px 20px;
  background: #feffff;
  box-shadow: 0px 0px 25px 0px rgba(102, 102, 102, 0.1);
  border-radius: 5px;
  .title {
    display: flex;
    align-items: center;
    height: 51px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dedfdf;
    .left {
      flex: 1;
      .title-text {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
    }
    /deep/ .ant-calendar-picker {
      width: 200px;
    }
    /deep/ .ant-btn {
      margin-left: 12px;
      font-size: 12px;
    }
  }
  .chart-panel {
    .btn-group {
      .ant-radio-button-wrapper {
        margin-right: 20px;
        border-radius: 3px;
      }
    }
    .cardType_5 {
      display: flex;
      justify-content: space-between;
    }
    & > .chart {
      height: 270px;
    }
  }
}
</style>
